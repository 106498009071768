import React from 'react';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';
import CardMedia from 'components/CardMedia';

import underConstruction from 'images/under_construction.jpg';
import SeoHelmet from 'components/SeoHelmet';
import { withStyles } from '@material-ui/styles';

const styles = () => ({
  media: {
    height: '100%',
    maxHeight: 400,
    margin: '0 auto',
    width: 'auto',
  },
});

const NotFound = ({ classes }) => (
  <Layout>
    <SeoHelmet title="404: Not found" />
    <CardMedia
      className={classes.media}
      image={underConstruction}
      title="404: Not found">
      <h1 hidden>NOT FOUND</h1>
    </CardMedia>
  </Layout>
);
NotFound.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NotFound);
