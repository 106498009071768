import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { CardMedia } from '@material-ui/core';

import BrokenImage from 'components/svg/BrokenImage';

/**
 * CardMedia
 */
class CardMediaImage extends Component {
  state = {
    error: false,
  };

  /**
   * Render the component
   *
   * @return {XML}
   */
  render() {
    const {
      src,
      classes,
      type,
      title,
      image,
      style,
      component,
      className,
    } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <div
          className={classNames({
            [classes.root]: classes.root && !type,
          })}>
          <BrokenImage color="inherit" />
        </div>
      );
    }

    return (
      <CardMedia
        title={title}
        src={src}
        image={image}
        className={className}
        component={component || 'img'}
        onError={this.handleImageError}
        style={style}
      />
    );
  }

  handleImageError = () => {
    this.setState({
      error: true,
    });
  };
}

CardMediaImage.propTypes = {
  src: PropTypes.string,
  image: PropTypes.string,
  classes: PropTypes.object,
  style: PropTypes.object,
  type: PropTypes.string,
  className: PropTypes.string,
  component: PropTypes.string,
  title: PropTypes.string,
};
CardMediaImage.defaultProps = {
  classes: {},
};

export default CardMediaImage;
